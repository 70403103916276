//custom-selection color
::selection {
	color: #fff;
	background: #000;
}

::-moz-selection {
	color: #fff;
	background: #000;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	vertical-align: baseline;
	font-family: $font-primary;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
	display: block;
}

/* make sure to set some focus styles for accessibility */
:focus {
	outline: 0;
}

ol,
ul {
	// list-style: none;
}
body {
	font-family: $body-font !important;
	font-size: $text-md; // 14px by default
	margin: 0;
	color: $text-color;
}
.dx-widget {
	font-family: $body-font;
	color: $text-color;
	font-size: $text-md;
}
a {
	color: $light-blue;
	text-decoration: none;

	&:hover {
		text-decoration: underline;
	}
}
button {
	cursor: pointer;
}

b,
strong {
	font-weight: $font-weight-bold;
}
img {
	border: 0; /* 1 */
	-ms-interpolation-mode: bicubic; /* 2 */
}

// Custom Scrollbar
//@include scrollbars(7px, $gray-500);

.rtl {
	direction: rtl;
}
h1,
h2,
h3,
h4,
h5,
h6 {
	color: $text-color;
}

h1 {
	font-weight: 500;
	font-size: 2.3rem;
	color: $text-color;
	margin-bottom: 5px;
}
h2 {
	font-weight: 500;
	font-size: 2rem;
	color: $text-color;
	margin-bottom: 5px;
}
h3 {
	font-weight: 500;
	font-size: 1.17rem;
	color: $text-color;
	margin-bottom: 5px;
}
h4 {
	font-weight: 500;
	font-size: 1rem;
	color: $text-color;
	margin-bottom: 5px;
}
h5 {
	font-weight: 500;
	font-size: 0.83rem;
	color: $text-color;
	margin-bottom: 5px;
}
h6 {
	font-weight: 500;
	//font-size: 0.67rem;
	color: $text-color;
	margin-bottom: 5px;
}
p {
	font-size: 0.9rem;
	line-height: 1.6rem;
	&.small {
		font-size: $text-sm-plus !important;
	}
}

// To hide the eye from a password input in MS Edge and IE

input[type='password']::-ms-reveal,
input[type='password']::-ms-clear {
	display: none;
}
input::-ms-reveal,
input::-ms-clear {
	display: none;
}
// To hide the eye from a password input in MS Edge and IE END OF CODE
