.app-l-page-wrap {
	display: flex;
	flex-direction: column;
	height: 100vh;
	min-height: 0;

	@media screen and (max-width: 1024px) {
		height: 100%;
	}

	&.full {
		height: 100%;

		.app-l-login-wrap {
			height: auto;
		}
	}
}

.step1Link {
	span {
		text-decoration: none;
		font-weight: 500;
		font-size: 16px;
		line-height: 150%;
		color: #3330d9;
		cursor: pointer;
	}
}

.headline {
	font-weight: 400;
	font-size: 34px;
	color: #000000;
}

.app-l-escape {
	all: unset;
	position: absolute;
	right: 34.67px;
	top: 34.67px;
	text-align: center;
	display: block;
	color: rgba(97, 99, 111, 1);
	font-size: 20px;
	font-weight: $font-weight-light;

	&:hover {
		color: $app-brand-primary;
	}

	span {
		display: block;
		font-size: 14px;
		font-weight: $font-weight-normal;
		line-height: 14px;
	}

	.rtl &,
	[dir='rtl'] & {
		right: auto;
		left: 34.67px;
	}
}

.facebookButton {
	width: 276px;
	height: 42px;
	border: 1px solid #bec1d2;
	border-radius: 25px;
	background-color: transparent;
}

.app-l-login-wrap {
	@include flexbox();
	height: 100%;

	@media screen and (max-width: 1024px) {
		flex-direction: column;
	}

	.app-l-login__left,
	.app-l-login__right {
		width: 50%;

		&.full {
			width: 100%;

			.app-l-login__content-wrap {
				overflow: visible;
				padding: 5% 0px 5% 0px;
				max-height: 100%;
			}
		}

		@media screen and (max-width: 1024px) {
			width: auto;
		}
	}

	.app-l-login__left {
		// background: #f2f9ff;
		display: flex;
		flex-direction: column;

		@media screen and (max-width: 1024px) {
			background: transparent;
		}

		.app-l-login__header {
			padding: 39px 47px 0px;

			.rtl &,
			[dir='rtl'] & {
				text-align: right;
			}

			@media screen and (max-width: 1024px) {
				padding: 24px 24px 0px;
			}

			img {
				cursor: pointer;
			}
		}

		.app-l-login__img-wrap {
			margin-top: auto;
			margin-bottom: auto;
			text-align: center;

			@media screen and (max-width: 1024px) {
				display: none;
			}

			img {
				// margin-left: 35px;
				margin: auto;
				position: relative;
				width: 40vw;

				@media screen and (max-width: 1200px) {
					width: 475px;
				}
			}
		}
	}

	.app-l-login__right {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		//background: $white;
		margin-top: 5vh;
		margin-bottom: 5vh;

		@media screen and (max-width: 1024px) {
			// margin-top: 59px;
		}

		.app-l-login__content-wrap {
			padding-right: 9vw;
			padding-left: 9vw;
			width: 100%;
			margin: auto;
			overflow: auto;

			&.-forgot-wrap {
				min-width: 400px;

				@media screen and (max-width: 767px) {
					min-width: auto;
				}
			}

			&::-webkit-scrollbar-track {
				// -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
				border-radius: 10px;
				// background-color: transparent;
				background-color: transparent;
			}

			&::-webkit-scrollbar {
				width: 4px;
				height: 9px;
				// background-color: #f2f9ff;
				background-color: transparent;
			}

			&::-webkit-scrollbar-thumb {
				border-radius: 10px;
				// background: rgba(190, 193, 210, 1);
				// background-color: $app-brand-primary;
				background-color: transparent;
			}

			@media screen and (max-width: 1024px) {
				max-width: initial;
				width: 400px;
				padding-left: 0;
				padding-right: 0;
			}

			@media screen and (max-width: 575px) {
				width: calc(100% - 32px);
			}

			.app-l-login__form-wrap {
				// margin-top: 20px;
				width: 100%;

				.form-group {
					margin-bottom: 0px;

					+ .form-group {
						margin-top: 20px;
					}
				}

				.app-c-form--postion {
					display: flex;

					.form-control {
						display: flex;
						width: 100%;
						//height: 52px;
						height: 40px;
						@include border-radius(6px);

						&:focus {
							@include noShadow();
							outline: none;
							border-color: $app-brand-primary;
						}
					}

					.app-c-btn--icon-only {
						position: absolute;
						right: 12px;
						left: auto;
						top: $text-sm-plus;
						//top: 20px;
						bottom: auto;
						font-size: 18px;

						.rtl &,
						[dir='rtl'] & {
							left: 12px;
							right: auto;
						}
					}
				}
			}
		}

		.app-l-login__right__header {
			display: flex;
			flex-direction: column;
			align-items: center;

			&.btm-border {
				border-bottom: 1px solid $generic-border-color;
			}

			h4 {
				font-weight: 500;
				font-size: 30px;
				line-height: normal;
				margin-top: 0px;
				margin-bottom: 14px;
				color: #0f0f0f;

				@media screen and (max-width: 1024px) {
					align-self: flex-start;
				}
			}

			h6 {
				font-weight: normal;
				font-size: 14px;
				line-height: normal;
				color: #61636f;
				margin-top: 0px;
				margin-bottom: 40px;
				text-align: center;

				&.dark {
					color: $text-color;
				}

				@media screen and (max-width: 1024px) {
					align-self: flex-start;
				}
			}
		}

		.app-l-login__right__btn-wrap {
			margin-top: 40px;
			display: flex;
			align-items: center;

			.app-c-btn {
				padding-top: 8px;
				padding-bottom: 8px;
				color: #61636f;
				font-weight: normal;
				min-width: 165px;

				img {
					margin-right: 12px;

					.rtl &,
					[dir='rtl'] & {
						margin-right: 0px;
						margin-left: 12px;
					}
				}
			}
		}

		.app-l-seperator {
			margin: 32px 0px;
			width: 100%;
			display: flex;
			align-self: center;
			justify-content: center;

			@media screen and (max-width: 1024px) {
				margin: 24px 0px;
			}

			h6 {
				font-weight: normal;
				font-size: $text-sm-plus;
				line-height: normal;
				letter-spacing: 0.1em;
				text-transform: uppercase;
				color: #bec1d2;
				position: relative;
				display: flex;
				align-items: center;
				margin: 0px 12px;
			}

			span {
				height: 1px;
				width: 120px;
				left: 0px;
				right: auto;
				background: #e9ebf4;
				align-self: center;

				@media screen and (max-width: 1024px) {
					width: 90px;
				}
			}
		}

		.app-l-onboard-options {
			width: 100%;

			@include flexbox();
			@include align-items(center);
			margin-top: 10px;

			.app-l-form-link {
				margin-left: auto;
				text-align: right;

				.rtl &,
				[dir='rtl'] & {
					margin-right: auto;
					margin-left: 0;
					text-align: right;
				}
			}
		}

		.app-l-form-link {
			width: auto;
			font-size: 14px;
			line-height: normal;
			color: $app-brand-primary;
			font-weight: 500;
			text-align: right;
			cursor: pointer;
			@include transition(color ease 0.3s);

			&:hover {
				text-decoration: none;
				color: $primary-btn-active-focus;
			}
		}

		.app-l-onboard-form-controls {
			margin-top: 26px;
			text-align: center;

			&.right-alined {
				text-align: right;
			}

			@media screen and (max-width: 1024px) {
				margin-top: 24px;
				margin-bottom: 24px;
			}

			.app-l-onboard__signup-link {
				margin-top: 24px;
				font-weight: normal;
				font-size: 14px;
				line-height: normal;
				color: #61636f;
			}
		}
	}
}

// Lesson list

.app-l-screen-header {
	min-height: 0px;

	// border-bottom: 1px solid #e4e6ed;
	// background: $white;
	// @include boxShadow(0px 1px 0px #e4e6ed);
	z-index: 10;
	//position: sticky;
	top: 0;

	.rtl &,
	[dir='rtl'] & {
		text-align: right;
	}

	.app-l-header-wrapper {
		@include flexbox();
		@include flex-direction(row);
		@include flex-grow(0);
		@include flex-shrink(0);
		@include align-items(center);

		.rtl &,
		[dir='rtl'] & {
			text-align: right;
		}

		padding: $text-sm-plus 40px;

		@media screen and (max-width: 991px) {
			padding: 10px 24px 8px 24px;
		}

		@media screen and (max-width: 767px) {
			padding: 10px 16px 8px 16px;
			flex-wrap: wrap;
		}
	}

	.app-c-error-content {
		background: lighten($validation-red, 45%);
		color: $overDueColor;
		font-size: $text-md;
		line-height: 14.3px;
		padding: 6px 8px;
		text-align: center;
		width: 100%;
		float: left;
		margin-top: 5px;
	}

	&.app-l-screen-header--white {
		background: $white;
	}

	.app-l-screen-head-controls {
		margin-left: auto;
	}

	.app-l-screen-head-wrap {
		position: relative;

		&:hover {
			.app-c-custom-tooltip {
				opacity: 1;
				top: 30px;

				@media screen and (max-width: 767px) {
					top: 25px;
				}
			}
		}

		.app-c-custom-tooltip {
			opacity: 0;
			top: 35px;
			z-index: 2;
		}

		@media (max-width: 992px) {
			.app-c-custom-tooltip {
				display: none;
			}
		}
	}

	.app-l-screen__heading {
		font-weight: 500;
		font-size: 20px;
		line-height: normal;
		margin-top: 0px;
		margin-bottom: 0px;
		color: #1e1919;
		@include truncate();
		max-width: 100%;
		min-width: 120px;
		position: relative;

		&.full-title {
			max-width: 100%;
			white-space: normal;
			overflow: visible;
			// @media screen and (max-width: 1200px) {
			// 	max-width: 96%;
			// }
			// @media screen and (max-width: 991px) {
			// 	max-width: 86%;
			// }
			// @media screen and (max-width: 768px) {
			// 	max-width: 68%;
			// }
			// @media screen and (max-width: 640px) {
			// 	max-width: 100%;
			// 	white-space: normal;
			// }
		}

		.rtl &,
		[dir='rtl'] & {
			text-align: right;
		}

		&.with-title {
			@include flexbox;
			@include align-items(center);

			@media (max-width: 767px) {
				@include flex-direction(column);
				@include align-items(flex-start);
			}
		}

		@media screen and (max-width: 1200px) {
			font-size: 18px;
			// max-width: 400px;
		}

		@media screen and (max-width: 991px) {
			font-size: 14px;
			// max-width: 320px;
		}

		@media screen and (max-width: 767px) {
			// max-width: 500px;
		}

		@media screen and (max-width: 600px) {
			// max-width: 480px;
		}

		@media screen and (max-width: 600px) {
			// max-width: 480px;
		}

		@media screen and (max-width: 575px) {
			max-width: 100%;
			word-break: break-word;
			white-space: break-spaces;
		}

		// @media screen and (max-width: 480px) {
		// 	max-width: 320px;
		// }
		// @media screen and (max-width: 400px) {
		// 	max-width: 280px;
		// }
	}

	.app-l-breadcrumb-holder {
		margin-top: 6px;
		flex-basis: 100%;
		max-width: 100%;
		flex-shrink: 0;
		display: flex;

		@media (max-width: 767px) {
			flex-direction: column;

			.app-c-last-updated {
				width: fit-content;
				margin-top: 10px;
			}
		}
	}

	.app-c-breadcrumb {
		@include flexbox();
		@include flex-wrap(wrap);
		@include align-items(center);

		a {
			color: $app-brand-primary;
			text-decoration: none;
		}

		.app-c-breadcrumb__prev {
			color: var(--theme-primary-color);
			font-size: $text-sm-plus;
			font-weight: 400;
			position: relative;
			padding-right: $text-sm-plus;
			margin-right: 6px;
			cursor: default;
			@include transition(color ease 0.2s);

			.rtl &,
			[dir='rtl'] & {
				padding-right: inherit;
				padding-left: $text-sm-plus;
				margin-right: inherit;
				margin-left: 6px;
			}

			&:after {
				content: '';
				width: 4px;
				height: 4px;
				border-radius: 50%;
				background: #d0d4f1;
				position: absolute;
				top: 1px;
				bottom: 0;
				right: 0px;
				margin: auto;

				.rtl &,
				[dir='rtl'] & {
					right: inherit;
					left: 0;
				}
			}

			&:last-child() {
				&:after {
					display: none;
				}
			}
		}

		.app-c-breadcrumb__active {
			color: #70696d;
			cursor: default;
			position: relative;
			font-size: $text-sm-plus;
			padding-right: 13px;
			margin-right: 10px;

			.rtl &,
			[dir='rtl'] & {
				padding-right: inherit;
				padding-left: 13px;
				margin-right: inherit;
				margin-left: 10px;
			}

			&:last-child {
				&:after {
					display: none;
				}
			}
		}
	}

	.app-l-screen-header__left {
		margin-right: auto;
		@include flexbox();
		@include flex-direction(row);
		@include justify-content(flex-start);
		@include align-items(flex-start);
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		padding-right: 10px;

		//flex-wrap: wrap;
		.rtl &,
		[dir='rtl'] & {
			margin-right: 0;
			margin-left: auto;
		}

		button {
			padding: 2px !important;
			position: relative;
			//bottom: 20px;
			height: auto;
			margin-right: 13px;
			background: none !important;
			font-size: 18px;
			margin-top: 2px;
			border-radius: 5px;
			transition: all 0.3s;

			&:hover,
			&:focus {
				// background: #e8e9f2!important;
				background: none;
				color: $text-color !important;
			}

			.rtl &,
			[dir='rtl'] & {
				margin-right: auto;
				margin-left: 10px;

				&::before {
					transform: scaleX(-1);
					display: block;
				}
			}

			.app-l-screen-header__left-holder {
				overflow: hidden;
			}
		}
	}

	.app-l-screen-header__left-group {
		@include flexbox();
		@include flex-direction(row);
		@include align-items(flex-end);
		flex-wrap: wrap;
		overflow: hidden;

		.custom-tooltip-header {
			top: 27px !important;
			padding: 2px 12px 0px !important;
		}

		.app-l-screen-header__left-holder {
			overflow: hidden;

			.app-c-custom-tooltip {
				top: 27px !important;
				padding: 2px 12px 0px !important;
			}
		}
	}

	.app-c-last-updated {
		background: $faded-blue;
		color: $app-brand-primary;
		font-size: $text-sm-plus;
		line-height: 14.3px;
		padding: 2px 8px;
		display: inline-block;
		@include border-radius(3px);
		vertical-align: middle;
		position: relative;
		margin-left: -10px;

		@media screen and (max-width: 1024px) {
			margin-left: 0;
		}

		@media screen and (max-width: 767px) {
			margin-left: 0px;
		}

		@media screen and (max-width: 575px) {
			margin-left: 0;
		}
	}

	.app-l-screen-header__right {
		margin-left: auto;
		display: flex;
		align-items: center;

		@media only screen and (max-width: 1024px) {
			// flex-basis: 60%;
			text-align: right;
		}

		.rtl &,
		[dir='rtl'] & {
			@media screen and (max-width: 991px) {
				text-align: left;
			}
		}

		@media screen and (max-width: 767px) {
			flex-basis: 100%;
			flex-shrink: 0;
			padding-top: 10px;
		}

		.app-l-language-switch {
			display: inline-block;
			margin-right: 16px;
			position: relative;

			@media screen and (max-width: 991px) {
				margin-right: 8px;
			}

			.rtl &,
			[dir='rtl'] & {
				margin-right: 0px;
				margin-left: 16px;

				@media screen and (max-width: 991px) {
					margin-right: 8px;
				}
			}

			.app-l-language-switch__btn {
				display: inline-block;
				min-width: 100px;
				background: rgba(233, 235, 244, 1);
				@include border-radius(5px);
				border: none;
				min-width: 82px;
				text-align: left;
				line-height: 14.22px;
				font-size: $text-md;
				padding: 7px 32px 7px 8px;
				position: relative;

				&::before {
					content: '\e972';
					font-family: 'qualify-tms';
					position: absolute;
					right: 8px;
					top: 7.5px;
					font-size: 10px;
				}
			}

			.app-l-language-switch__options {
				z-index: 1;
				position: absolute;
				right: 0;
				top: 40px;
				background: #fff;
				width: 250px;
				@include boxShadow(0px 9px 21px 0px rgba(0, 0, 0, 0.1316));
				@include border-radius(6px);
				padding: 4px 0px;
				opacity: 0;
				filter: alpha(opacity=0);
				pointer-events: none;
				transition: all ease 0.2s;

				.rtl &,
				[dir='rtl'] & {
					left: 0;
					right: auto;
				}

				&.active {
					opacity: 1;
					filter: alpha(opacity=100);
					pointer-events: auto;
					top: 33px;
					z-index: 3;
				}

				ul {
					margin: 0;
					padding: 0;

					li {
						display: block;
						padding: 8px 12px;
						transition: all ease 0.2s;

						&:hover,
						&.selected {
							background: rgba(233, 235, 244, 0.5);
						}

						.app-l-language-switch__options-items {
							width: 100%;
							@include flexbox();
							@include flex-direction(row);
							@include justify-content(space-between);
							@include align-items(center);
							background: none;
							border: none;
							padding: 0;
							height: auto !important;
							line-height: 16px;
							font-size: $text-md;

							.options-items__lbl {
								color: rgba(97, 99, 111, 1);
							}

							.options-items__val {
								text-align: left;
								flex-basis: 66px;
								position: relative;
								padding-left: 22.4px;
								font-size: $text-lg;

								.rtl &,
								[dir='rtl'] & {
									text-align: right;
									padding-right: 22.4px;
									padding-left: 0;
								}

								&::before {
									content: '\e92a';
									font-family: 'qualify-tms';
									left: 0;
									top: 0;
									position: absolute;
									font-size: 8px;
									color: rgba(97, 99, 111, 1);

									.rtl &,
									[dir='rtl'] & {
										@include transform(rotate(180deg));
										left: auto;
										right: 0;
									}
								}
							}
						}
					}
				}
			}
		}

		@media only screen and (max-width: 1024px) {
			button {
				div {
					display: none;
				}

				// i {
				// 	margin: 0;
				// }
			}
		}

		.rtl &,
		[dir='rtl'] & {
			margin-right: auto;
			margin-left: 0;
		}

		.app-c-btn--addon {
			i {
				position: relative;
				top: -1px;

				@media only screen and (max-width: 991px) {
					top: inherit;
				}
			}
		}

		button {
			min-width: 40px;
			border-radius: 28px;
			position: relative;
			z-index: 3;

			// min-height: 40px !important;
			@media only screen and (max-width: 991px) {
				min-width: 30px;
				height: 30px;
				@include inline-flex();
				flex-direction: row;
				@include align-items(center);
				@include justify-content(center);
				font-size: 12px;

				i {
					&.icon-plus:not(.mr-6) {
						margin-right: 0 !important;

						[dir='rtl'] & {
							margin-left: 0 !important;
						}
					}
				}
			}

			i {
				&.icon-plus {
					margin-right: 6px;
					// @media screen and (max-width: 991px) {
					// 	margin-right: 0;
					// }
				}

				&.icon-plus,
				&.icon-times {
					font-size: 10.5px !important;
				}

				&.icon-save {
					font-size: 13.5px;
				}

				// .rtl &,
				// [dir='rtl'] & {
				// 	@media screen and (max-width: 991px) {
				// 		margin-left: 6px;
				// 		margin-right: 0;
				// 	}
				// }
			}
		}

		.app-c-btn {
			padding: 10px 16px;

			& + * {
				margin-left: 8px;
			}

			@media only screen and (max-width: 991px) {
				padding: 2px 10px;
			}

			.icon-arrow-right {
				color: rgba(255, 255, 255, 0.6);
				font-size: 13.06px;
				margin-right: 0;
				margin-left: 6px;
				position: relative;
				top: -1px;
				margin-left: 7px;

				@media screen and (max-width: 991px) {
					margin-left: 0px;
				}

				&::before {
					color: rgba(255, 255, 255, 0.6);
					font-size: 12px;
				}
			}
		}

		.app-c-switch {
			margin-right: 16px;
			min-width: 80px;

			@media screen and (max-width: 991px) {
				margin-right: 8px;
			}

			.rtl &,
			[dir='rtl'] & {
				margin-right: 0;
				margin-left: 16px;

				@media screen and (max-width: 991px) {
					margin-left: 8px;
				}
			}

			span {
				@include justify-content(center);
			}
		}

		.app-c-next-btn {
			i {
				transform: rotate(90deg);
				margin-left: 8px;
				opacity: 0.5;
				margin-right: -5px;

				.rtl &,
				[dir='rtl'] & {
					transform: rotate(-90deg);
					margin-left: 0;
					margin-right: 8px;
				}
			}
		}

		&.app-c-btn--addon {
			i {
				@media only screen and (max-width: 991px) {
					margin-right: 6px;
				}
			}
		}
	}
}

//overriding external svg
.icon-reset-password {
	font-size: $text-xl;
}

.app-c-alert-msg {
	display: block;
	margin: auto;
	width: calc(100% - 80px);
	border: 1px solid $default-red;
	color: $default-red;
	font-size: $text-xl;
	padding: 8px 24px;
	@include border-radius(6px);
	margin-bottom: 10px;
	text-align: center;
	font-weight: $font-weight-semibold;
	background: rgba($default-red, 0.15);
}

.app-l-form-link {
	color: $app-brand-primary;
	font-weight: $font-weight-semibold;
}
