.app-c-screen-nav {
	@include flexbox();
	@include flex-direction(row);
	@include justify-content(flex-end);
	@media screen and (max-width: 1200px) {
		flex-wrap: wrap;
	}
}
.app-c-screen-nav__item {
	display: inline-block;
	padding: 0px 0px;
	font-size: $text-lg;
	color: $app-brand-primary;
	text-decoration: none;
	margin: 8px 16px;
	@media screen and (max-width: 1200px) {
		padding: 5px 0px;
		margin: 8px;
	}
	&:hover,
	&.active {
		color: $app-brand-primary-dark;
		text-decoration: none;
	}
}
.app-c-search-wrapper {
	@include flexbox();
	@include flex-direction(row);
	@include justify-content(space-between);
	align-items: center;
	@media screen and (max-width: 991px) {
		flex-wrap: wrap;
	}
	.app-c-search-wrapper__box {
		flex-grow: 1;
		padding-right: 16px;
		.rtl &,
		[dir='rtl'] & {
			padding-right: 0px;
			padding-left: 16px;
		}
		@media screen and (max-width: 991px) {
			padding: 0px 0px 8px 0px;
		}
	}

	.app-l-info-wrap {
		//gap: 8px;
		position: relative;
		margin: 0px -4px;
		@media screen and (max-width: 991px) {
			flex-wrap: nowrap;
			flex-grow: 1;
		}
		@media screen and (max-width: 768px) {
			flex-wrap: wrap;
			flex-grow: 1;
		}
		button {
			&.app-l-info__box {
				background: none;
				@include border-radius(6px);
				border: 1px solid #bec1d2;
				text-align: left;
				padding: 8px 40px 7px 16px;
				display: inline-block;
				position: relative;
				@include transition(all ease 0.2s);
				margin: 4px;
				@include flexbox();
				@include flex-direction(column);
				@include justify-content(space-between);
				@include align-items(flex-start);
				height: 52px;
				[dir='rtl'] & {
					text-align: right;
					padding: 7px 16px 4px 36px;
				}
				@media screen and (max-width: 768px) {
					flex-grow: 1;
				}
				&.active,
				&:hover {
					border-color: var(--theme-primary-color);
					@include boxShadow(0px 0px 5px 0px var(--theme-primary-color-600));
				}
			}
			.app-c-link-arrow {
				font-size: 8px;
				position: absolute;
				bottom: $text-sm-plus;
				right: $text-sm-plus;
				[dir='rtl'] & {
					right: inherit;
					left: $text-sm-plus;
				}
			}
			.app-l-text {
				span {
					position: relative;
					margin-left: 20px;
					.rtl &,
					[dir='rtl'] & {
						margin-right: 20px;
						margin-left: 0;
					}
					&::before {
						position: absolute;
						left: -$text-sm-plus;
						top: 8px;
						width: 4px;
						height: 4px;
						border-radius: 50%;
						content: '';
						background: #d0d4f1;
						display: block;
						[dir='rtl'] & {
							right: 6px;
							left: inherit;
						}
					}
				}
			}
		}
	}

	.app-l-title-small {
		padding-bottom: 0;
		white-space: nowrap;
		font-size: $text-lg;
	}
	.app-l-text {
		font-weight: $font-weight-semibold;
		font-size: $text-xl;
		display: flex;
		white-space: nowrap;
		margin-bottom: 0px !important;
	}
}
.app-l-text__view {
	font-size: $text-md;
	color: $app-brand-primary;
	text-decoration: none;
	font-weight: $font-weight-normal;
	display: inline-block;

	&:hover {
		text-decoration: none;
		color: $text-color;
	}
}
.app-c-search-field {
	display: block;
	position: relative;
	&::before {
		content: '\e92b';
		font-family: 'qualify-tms';
		position: absolute;
		left: 17.24px;
		top: 0;
		bottom: 0;
		margin: auto;
		line-height: 40px;
		display: block;
		color: $text-muted;
		font-size: 16px;
		.rtl &,
		[dir='rtl'] & {
			left: auto;
			right: 17.24px;
		}
	}

	.form-control {
		font-size: $text-lg;
		color: $text-color;

		padding: 0px 13px 0px 13px;
		height: 40px;
		border: 1px solid #bec1d2;
		font-size: $text-lg;
		[dir='rtl'] & {
			padding: 0px 52px 0px 70px;
		}
		&:focus {
			border-color: var(--theme-primary-color);
			@include boxShadow(0px 0px 5px 0px var(--theme-primary-color-600));
			outline: none;
		}
	}
	.main-search {
		padding: 0px 70px 0px 52px;
		@media (max-width: 500px) {
			padding: 0px 35px 0px 41px;
			.rtl &,
			[dir='rtl'] & {
				padding: 0px 41px 0px 35px;
			}
		}
		.rtl &,
		[dir='rtl'] & {
			padding: 0px 52px 0px 70px;
		}
	}

	.app-c-search-btns {
		position: absolute;
		padding: 0;
		border: none;
		background: none;
		color: #61636f;

		@include transition(all ease 0.2s);
		&:hover {
			color: $app-brand-primary;
		}
	}
}
.app-c-search__fiter-btn {
	top: $text-sm-plus;
	right: 17.6px;
	font-size: 12.8px;
	.rtl &,
	[dir='rtl'] & {
		right: inherit;
		left: 17.6px;
	}
	&.notification {
		&::after {
			position: absolute;
			@include border-radius(50%);
			display: block;
			width: 8px;
			height: 8px;
			background: #ff3b3b;
			top: 0px;
			right: -5px;
			content: '';
		}
	}
}
.app-c-search__close-btn {
	top: 14.8px;
	right: 42px;
	font-size: 10px;
	opacity: 0;
	color: #61636f;
	filter: alpha(opacity=0);
	.rtl &,
	[dir='rtl'] & {
		right: auto;
		left: 42px;
	}
	&.active {
		opacity: 1;
		filter: alpha(opacity=100);
	}
}
.app-c-search__dropdown {
	z-index: 3;
	display: block;
	position: absolute;
	//top: 42px;
	//left: 0;
	right: 0;
	background: #fff;
	//width: 100%;
	opacity: 0;
	filter: alpha(opacity=0);
	pointer-events: none;
	@include boxShadow(0px 2px 8px 0px var(--theme-primary-color-200));
	@include border-radius(6px);
	@include transition(all ease 0.2s);
	@include transform(translate(0px, 6px));
}
.app-c-search-list {
	&.active {
		opacity: 1;
		filter: alpha(opacity=100);
		pointer-events: auto;
		@include transform(translate(0px, 0px));
	}
	ul {
		margin: 0;
		padding: 0;
	}
	li {
		display: block;
		position: relative;
		padding: 12px 80px 12px 24px;
		cursor: pointer;
		[dir='rtl'] & {
			text-align: right;
			padding: 12px 24px 12px 80px;
		}
		&.selected,
		&:hover {
			background: #f4f5f9;
		}
		.app-c-search-list__label {
			margin: 0;
			line-height: normal;
			span {
				font-weight: $font-weight-semibold;
			}
		}
		.app-c-search-list__sub-label {
			color: #61636f;
			font-size: $text-lg;
		}
		.app-c-search-list__time {
			position: absolute;
			top: 12px;
			right: 24px;
			color: #61636f;
			font-size: $text-md;
			[dir='rtl'] & {
				right: inherit;
				left: 24px;
			}
		}
	}
}
.app-c-search-filter {
	padding: 15px 24px;
	&.active {
		opacity: 1;
		filter: alpha(opacity=100);
		pointer-events: auto;
		@include transform(translate(0px, 0px));
	}
	.css-yk16xz-control {
		border: 1px solid #bec1d2;
		@include border-radius(6px);
		.css-g1d714-ValueContainer {
			padding: 3px;
		}
	}

	label {
		margin-bottom: 6px;
		font-size: $text-lg;
		color: #61636f;

		line-height: 22px;
	}
}
.app-c-search-filter__footer {
	padding: 5px 0px 3px 0px;
	text-align: right;
	@media (max-width: 500px) {
		display: flex;
		flex-direction: column;
		.app-c-btn {
			+ .app-c-btn {
				margin: 10px 0 0;
			}
		}
	}
	.app-c-btn {
		.icon-search {
			font-size: 16px;
			&::before {
				color: #fff;
			}
		}
	}
}

.app-c-search-tags {
	border-top: 1px solid #e9ebf4;
	@include flexbox();
	@include justify-content(space-between);
	height: 0;
	overflow: hidden;
	position: relative;
	top: 0px;
	margin-top: 10px;
	margin-bottom: -24px;
	margin-left: -24px;
	margin-right: -24px;
	left: 0;
	background: #fff;
	opacity: 0;
	filter: alpha(opacity=0);
	pointer-events: none;
	@include boxShadow(0px 4px 4px 0px var(--theme-primary-color-200));
	@include border-radius(0px 0px 6px 6px);
	@include transition(all ease 0.2s);
	@include transform(translate(0px, 6px));
	padding: 0px;

	z-index: 2;

	&.active {
		padding: 12px 24px 12px 28px;
		opacity: 1;
		height: auto;
		overflow: visible;
		filter: alpha(opacity=100);
		pointer-events: auto;
		@include transform(translate(0px, 0px));
	}
	.app-c-card__link {
		position: relative;
		top: 10px;
		min-width: 54px;
	}
	.app-l-tags {
		padding: 8px $text-sm-plus 6px 8px;
	}
}

.app-c-recently-added {
	@include flexbox();
	@include flex-direction(row);
	@include justify-content(flex-start);
	position: relative;
	flex-wrap: wrap;
	margin: 0px -4px;
	@media screen and (max-width: 767px) {
		margin: 0px 0px;
	}
	.app-c-recently-added__items {
		cursor: pointer;
		position: relative;
		width: calc((100% / 6) - 10px);
		margin: 10px 5px;
		margin-top: 0;
		@include border-radius(4px);
		background: rgba(233, 235, 244, 0.5);
		padding: 16px 32px 14px 16.8px;
		@include flexbox();
		@include flex-direction(column);
		@include justify-content(space-between);
		@include align-items(flex-start);
		@media screen and (max-width: 1200px) {
			flex-basis: 32.33%;
			margin: 5px 0.5%;
		}
		@media screen and (max-width: 991px) {
			flex-basis: 49%;
		}
		@media screen and (max-width: 767px) {
			flex-basis: 100%;
			margin: 5px 0;
		}
		&:hover {
			background: rgba(233, 235, 244, 0.8);
		}
		.app-c-recently-added__botom {
			width: 100%;
		}
		.app-c-btn--icon-only {
			position: absolute;
			top: 10px;
			right: 10px;
			font-size: 18px;
			color: $gray01;
			&:hover {
				color: $app-brand-primary;
			}
		}
		h6 {
			font-size: $text-xl;
			margin-bottom: 2px;
			line-height: 18.96px;
			//max-height: 38px;
			word-break: break-word;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			max-width: 98%;
		}
		.app-l-title-small {
			line-height: 16px;
			margin-bottom: 10px;
			padding: 0;
			word-break: break-word;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			max-width: 98%;
			&.md {
				line-height: 15.6px;
				margin-bottom: 0 !important;
				padding-bottom: 0 !important;
			}
		}
	}
}
.app-c-card__flex-head {
	@include flexbox();
	@include justify-content(space-between);
	align-items: flex-start;
}
.app-c-card__link {
	display: inline-block;
	color: $app-brand-primary;
	font-size: $text-lg;
	line-height: 16.59px;
	text-decoration: none;
	padding-top: 2px;
	&:hover {
		text-decoration: none;
	}
}
.app-c-tile-wrapper {
	@include flexbox();
	position: relative;
	margin: 0px -8px;
	flex-wrap: wrap;
	justify-content: flex-start;
}
.cover-image {
	.--wide {
		width: 100%;
		margin: 0;
		picture {
			img {
				object-fit: cover;
				width: 330px;
				height: 238px;
				@media screen and (max-width: 767px) {
					width: 100%;
					height: 100%;
				}
			}
		}
	}
}
.app-c-tile-wrapper__card {
	cursor: pointer;
	margin: 0px 8px 16px 8px;
	border: 1px solid #bec1d2;
	@include border-radius(4px);
	position: relative;
	width: calc((100% / 4) - 16px);
	@media screen and (max-width: 1200px) {
		width: calc((100% / 3) - 16px);
	}
	@media screen and (max-width: 991px) {
		width: calc((100% / 2) - 16px);
	}
	@media screen and (max-width: 767px) {
		width: calc((100%) - 16px);
	}
	.app-c-tile-wrapper__img {
		position: relative;
		display: block;
		height: 190px;
		img {
			width: 100%;
			height: 100%;
			min-height: 190px;
			max-height: 190px;
			object-fit: cover;
			@include border-radius(3px 3px 0px 0px);
		}
	}
}
.app-c-tile-wrapper__card-image {
	cursor: pointer;
	border: 1px solid #bec1d2;
	@include border-radius(4px);
	position: relative;
	height: 100%;
	.app-c-tile-wrapper__img {
		position: relative;
		display: block;
		font-size: 0;
		height: 100%;
		img {
			width: 100%;
			@include border-radius(3px 3px 0px 0px);
			object-fit: cover;
		}
	}
}
.app-c-btn-options {
	position: absolute;
	background: rgba(255, 255, 255, 0.7);
	@include flexbox;
	@include flex-direction(column);
	@include justify-content(space-between);
	@include align-items(center);
	padding: 3px;
	width: $text-sm-plus;
	height: 21px;
	right: 12px;
	top: 12px;
	@include border-radius(2px);
	@include transition(all ease 0.2s);
	border: none;
	img {
		min-height: 15px !important;
		max-height: 15px !important;
		width: 3px !important;
		min-width: 3px !important;
		height: 15px !important;
		border-radius: 0 !important;
		object-fit: unset !important;
	}
	.rtl &,
	[dir='rtl'] & {
		right: inherit;
		left: 12px;
	}
	&:hover {
		background: rgba(255, 255, 255, 0.8);
	}
	.app-c-btn-options__dots {
		background: rgba(97, 99, 111, 1);
		margin: 1px;
		width: 3px;
		height: 3px;
		display: block;
		@include border-radius(50%);
	}
}
.app-c-btn-option-box {
	background: #fff;
	position: absolute;
	top: 37px;
	right: 12px;
	display: block;
	padding: 6px 0px;
	@include border-radius(4px);
	@include boxShadow(0px 9px 21px 0px rgba(0, 0, 0, 0.1316));
	min-width: 97px;
	opacity: 0;
	filter: alpha(opacity=0);
	pointer-events: none;
	@include transition(all ease 0.2s);

	@include transform(translate(0px, 5px));
	.rtl &,
	[dir='rtl'] & {
		right: inherit;
		left: 12px;
	}

	&.active {
		opacity: 1;
		filter: alpha(opacity=100);
		pointer-events: auto;
		@include transform(translate(0px, 0px));
	}
}
.app-c-btn-option__links {
	border: none;
	background: none;
	text-decoration: none;
	color: $text-color;
	font-size: $text-md;
	line-height: 16px;
	display: block;
	margin: 0;
	padding: 6px 12px;
	@include transition(all ease 0.2s);
	width: 100%;
	text-align: left;
	.rtl &,
	[dir='rtl'] & {
		text-align: right;
	}
	&:hover {
		background: #f4f6fa;
	}
}
.app-c-tile-wrapper__tag {
	position: absolute;
	background: rgba(255, 255, 255, 0.7);
	top: 12px;
	left: 12px;
	@include border-radius(4px);
	display: inline-block;
	line-height: 16px;
	padding: 3px 12px 3px 25px;
	// padding: 3px 23px 3px 26px;
	color: rgba(30, 25, 25, 1);
	font-size: 12px;
	.rtl &,
	[dir='rtl'] & {
		left: inherit;
		right: 12px;
		padding: 3px 26px 3px 23px;
	}
	&::before {
		position: absolute;
		left: 8px;
		top: 0;
		bottom: 0;
		margin: auto;
		display: block;
		width: 8px;
		height: 8px;
		@include border-radius(50%);
		content: '';
		.rtl &,
		[dir='rtl'] & {
			left: inherit;
			right: 8px;
		}
	}
	&.date {
		top: unset;
		left: unset;
		bottom: 5px;
		right: 5px;
		.rtl &,
		[dir='rtl'] & {
			left: 5px;
			right: inherit;
		}
	}
	&.date2 {
		top: unset;
		left: unset;
		bottom: 35px;
		right: 5px;
		.rtl &,
		[dir='rtl'] & {
			left: 5px;
			right: inherit;
		}
	}
	&.published {
		&::before {
			background: $publishedColor;
		}
	}
	&.inProgress {
		&::before {
			background: $inProgressColor;
		}
	}
	&.draft {
		&::before {
			background: $draftColor;
		}
	}
	&.overdue {
		&::before {
			background: $overDueColor;
		}
	}
	&.scheduled {
		&::before {
			background: $scheduledColor;
		}
	}
	&.completed {
		&::before {
			background: $completedColor;
		}
	}
}
.app-c-tile-wrapper__text {
	padding: 14px 12px 8px 12px;
	max-width: 100%;
	// max-height: 140px;

	overflow: hidden;
	@media screen and (max-width: 767px) {
		max-width: 100%;
	}
	h5 {
		font-size: $text-xl;
		color: $app-brand-primary;
		line-height: 18.96px;
		font-weight: $font-weight-semibold;
		max-height: 19px;
		@include truncate();

		@media screen and (max-width: 767px) {
			white-space: inherit;
			overflow: visible;
			text-overflow: inherit;
			max-height: inherit;
			width: 100%;
		}
	}
	.app-l-title-small {
		line-height: 15.6px;
		padding-bottom: 0;
	}
	.app-l-tag-wrap {
		margin-bottom: 10px;
		max-height: 36px;
		min-height: 36px;
		@include truncate();
	}
}
.app-c-tile-wrapper__subText {
	line-height: 14px;
	font-size: 12px;
	color: rgba(97, 99, 111, 1);
	display: block;
	margin-bottom: 12px;
	margin-top: 4px;
	max-height: 14px;
	overflow: hidden;
	word-wrap: break-word;
}
.app-c-tile-wrapper__para {
	line-height: 16px;
	font-size: 12px;
	color: rgba(30, 25, 25, 1);
	margin-bottom: 8px;
	max-height: 32px;
	overflow: hidden;
	word-wrap: break-word;
	span {
		font-weight: $font-weight-semibold;
		& + * {
			margin-left: 4px;
		}
	}
	button {
		padding-top: 2px;
		position: relative;
		top: -2px;
	}
	.icon-info {
		font-size: 13px;
		display: inline-block;
		vertical-align: middle;
		position: relative;
		top: -1px;
	}
}

.app-c-date-picker {
	position: relative;
	&::after {
		content: '\e91c';
		font-family: 'qualify-tms';
		position: absolute;
		right: 13px;
		top: 9px;
		font-size: 14px;
		color: #61636f;
		pointer-events: none;
		.rtl &,
		[dir='rtl'] & {
			right: auto;
			left: 13px;
		}
	}
	.react-datepicker-wrapper {
		display: block;
	}
	.form-control {
		padding-right: 130px;
		@include border-radius(6px);
		.rtl &,
		[dir='rtl'] & {
			padding-right: 10px;
			padding-left: 30px;
		}
	}
}
