.userTypeSignUp {
	.pageHeader {
		.logoBox {
			display: flex;
			align-items: center;
			justify-content: center;
		}
		.cancelButton {
			margin-bottom: -30px;
			padding-left: 4%;
			a {
				padding: 7px;
				text-decoration: none;
				color: #3330d9;
				font-weight: 500;
				font-size: 15px;
			}
			a:hover {
				// padding:7px;
				background-color: rgba(80, 77, 255, 0.04);
				border-radius: 25px;
			}
		}
	}
	.boxContainer {
		display: flex;
		//align-items: center;
		//justify-content: center;
		padding: 5%;
		text-align: center;
		img {
			height: 160px;
		}
	}
	.headline {
		font-size: 20px;
		font-weight: 500;
	}
	.pdt-5 {
		padding-top: 5%;
	}
	.pdt-10 {
		padding-top: 10%;
	}
	.toggleButton {
	}
	.inputField {
		//width: 50%;
	}
	.MuiFormControl-root {
		//width: 50% !important;
	}
	.stepper {
		margin-top: 20%;
	}

	.step-1 {
		.step1Link {
			span {
				text-decoration: none;
				font-weight: 500;
				font-size: 16px;
				line-height: 150%;
				color: #3330d9;
				cursor: pointer;
			}
		}
	}

	.step-2 {
		.avatarImg {
			img {
				width: 40px;
				height: 40px;
			}
		}
		.headline {
			font-weight: 400;
			font-size: 34px;
			color: #000000;
		}
		.account-ready {
			font-weight: 400;
			font-size: 16px;
			color: rgba(0, 0, 0, 0.87);
		}
	}
	.signUpForm {
		.tAndC {
			width: 76%;
			margin-left: 10%;
		}
	}
}

iframe {
	.qJTHM {
		height: 50px !important;
	}
}
